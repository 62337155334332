import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";

const LanguageSwitch = ({ closeMenu }) => {
  const i18n = useSelector((reduxStore) => reduxStore.i18n);

  if (i18n.translations.length > 1) {
    return (
      <div className="language-switch" onClick={closeMenu ? closeMenu : null}>
        {i18n.translations.map((translation) => (
          <a
            key={translation.language.id}
            href={translation.url.path}
            className={classNames({
              active: i18n.currentLanguage === translation.language.id,
              //"not-translated": !translation.path,
            })}
          >
            {translation.language.id.toUpperCase()}
          </a>
        ))}
      </div>
    );
  }

  return null;
};

LanguageSwitch.propTypes = {
  closeMenu: PropTypes.func,
};

export default LanguageSwitch;
