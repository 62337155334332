import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import teaserNodeQueryEvent from "../../../teaser-base/queries/teaser-node-query-event.graphql";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../teaser-base/event/teaser-event";
import ErrorBoundary from "../../../../error-boundary";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const ParagraphCalendar = ({ nodes }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-calendar": true
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          { nodes.entityQuery &&
            nodes.entityQuery.items &&
            nodes.entityQuery.items.length > 0 && (
              <>
                {nodes.entityQuery.items.map((item, index) => (
                  <ErrorBoundary key={index}>
                    <TeaserEvent item={item} />
                  </ErrorBoundary>
                ))}
              </>
            )}
        </div>
      </div>
    </section>
  );
};

ParagraphCalendar.propTypes = {
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      total: PropTypes.number,
      items: PropTypes.arrayOf(teaserEventPropTypes),
    }),
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryEvent, {
    name: "nodes",
    options: () => {
      return {
        variables: {
          type: "event",
          filterDateEnabled: false,
        },
      };
    },
  })(ParagraphCalendar)
);
