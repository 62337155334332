import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import Tags from "../../tags/tags";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNewsTimeline extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-news teaser-news-timeline">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={this.props.item.url?.path || this.props.item.path?.alias}
        >
          {this.props.item.fieldImage && (
            <Image
              data={this.props.item.fieldImage.fieldMediaImage}
              nodeTitle={this.props.item.title}
            />
          )}

          <FieldTime
            timestamp={true}
            date={
              this.props.item.publishedAtRawField?.first.value ||
              this.props.item.publishedAtRawField?.first.publishedAtOrNow
            }
            format={"DD.MM.YYYY"}
          />

          <h3>{this.props.item.title}</h3>

          {this.props.item.fieldText && (
            <div
              className="teaser text"
              dangerouslySetInnerHTML={{
                __html: this.props.item.fieldText,
              }}
            />
          )}

          <Tags tags={this.props.item.fieldTags} />

        </Link>
      </article>
    );
  }
}

TeaserNewsTimeline.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNewsTimeline));
