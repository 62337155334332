import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";

import menuQuery from "../general-components/navigation/menu-query.graphql";
import { Menu } from "../general-components/navigation/menu";
import Search from "./../general-components/search/search";
import classNames from "classnames";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * Close on menu link click.
 */
const SimpleNavigation = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleFullScreenMenu = () => {
    setMenuOpen((prev) => {
      if (prev) {
        document.body.classList.remove("fullscreen-menu-open");
      } else {
        document.body.classList.add("fullscreen-menu-open");
      }
      return !prev;
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      toggleFullScreenMenu();
    }
  };

  return (
    <>
      <Menu menuName="MAIN" navClassName="col main-menu-desktop" />

      {props.showSearch && <Search />}

      <div
        className={classNames({
          "toggle-fullscreen-menu": true,
          active: menuOpen,
        })}
        tabIndex={0}
        aria-label="toggle-full-screen-menu"
        onClick={toggleFullScreenMenu}
        onKeyDown={handleKeyDown}
      >
        <svg className="hamburger-menu" viewBox="0 0 24 24">
          <line className="hamburger-line" x1="2" y1="6" x2="22" y2="6" />
          <line className="hamburger-line" x1="2" y1="12" x2="22" y2="12" />
          <line className="hamburger-line" x1="2" y1="18" x2="22" y2="18" />
        </svg>
      </div>

      <div id="fullscreen-menu" className={menuOpen ? "active" : ""}>
        <div className="nav-wrap">
          <div className="container">
            <div className="row">
              <div className="col-14 offset-2 ">
                <Menu navClassName="main-menu-desktop" menuName="MAIN" />
              </div>
            </div>
          </div>

          <div className="frequently-clicked">
            <div className="container">
              <div className="row">
                <div className="col-14 offset-2">
                  <Menu
                    menuName="FREQUENTLY_CLICKED"
                    showLabel={true}
                    itemOnClickHandler={() => toggleFullScreenMenu()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          /*TODO: Recursive? */
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

SimpleNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: () => ({
        variables: {
          name: "MAIN",
          /*language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",*/
        },
      }),
      name: "mainMenuQuery",
    })
  )(SimpleNavigation)
);
