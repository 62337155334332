import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import ParagraphFormular from "../../general-components/content-base/paragraphs/webform/paragraph-form";
import webformSubmissionQuery from "./webform-submission.graphql";
import ParagraphSectionheading from "../../general-components/content-base/paragraphs/section-heading/paragraph-sectionheading";

const WebformPage = (props) => {
  const [submissionData, setSubmissionData] = useState(null);
  const { loading } = useQuery(webformSubmissionQuery, {
    variables: {
      sid: props.match.params.submissionId,
    },
    skip: !props.match.params.submissionId,
    onCompleted: (data) =>
      setSubmissionData(JSON.parse(data.webformSubmissionById.data)),
  });

  if (loading) {
    return null;
  }

  return (
    <div>
      <ParagraphSectionheading
        content={{
          fieldHeading: props.match.params.webformId.replace(/_/g, " "),
        }}
      />
      <ParagraphFormular
        content={{
          fieldFormularAuswahl: { targetId: props.match.params.webformId },
        }}
        defaultInitialValues={submissionData}
        submissionId={props.match.params.submissionId}
      />
    </div>
  );
};

WebformPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default WebformPage;
