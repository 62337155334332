import React, { useRef, useState } from "react";
import Image from "../../../image/image";
import Slider from "react-slick";
import ErrorBoundary from "../../../../error-boundary";
import classNames from "classnames";
import Video from "../video/components/video";
import CustomArrow from "./custom-arrow";
import PropTypes from "prop-types";

const ParagraphMediaGallery = ({ content }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const updateSlideIndex = (next) => {
    setSlideIndex(next);
  };

  const sliderSettings = {
    arrows: true,
    dots: false,
    nextArrow: <CustomArrow classNames={classNames} />,
    prevArrow: <CustomArrow classNames={classNames} />,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    initialSlide: slideIndex,
    beforeChange: (_current, next) => {
      updateSlideIndex(next);
    },
  };

  const sliderRef = useRef(null);

  return (
    <section
      className={classNames({
        "paragraph-media-gallery": true,
      })}
    >
      <div className="container">
        <div className="row">
          <div className="col-16">
            <Slider {...sliderSettings} ref={sliderRef}>
              {content.fieldMultimediaRawField?.list?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.entity.entityBundle === "image" && (
                    <ErrorBoundary>
                      <article
                        className={`media-image ${
                          item.entity.fieldMediaImage.style.height /
                            item.entity.fieldMediaImage.style.width >
                          1.2
                            ? "portrait"
                            : "landscape"
                        }`}
                        key={index}
                      >
                        <Image data={item.entity.fieldMediaImage} />
                      </article>
                      {(item.customCaption ||
                        item.entity.fieldCaption ||
                        item.entity.fieldCredit) && (
                        <div className="info-wrap">
                          {(item.customCaption || item.entity.fieldCaption) && (
                            <span className="caption">
                              {item.customCaption || item.entity.fieldCaption}
                            </span>
                          )}
                          {item.entity.fieldCredit && (
                            <span className="credit">
                              Copyright: {item.entity.fieldCredit}
                            </span>
                          )}
                        </div>
                      )}
                    </ErrorBoundary>
                  )}
                  {(item.entity.entityBundle === "embedded_video" ||
                    item.entity.entityBundle === "video") && (
                    <ErrorBoundary>
                      <React.Fragment key={index}>
                        <Video content={item.entity} />
                        <div
                          className={classNames({
                            "caption-wrap": true,
                          })}
                        >
                          {item.customCaption && (
                            <span className="caption">
                              {item.customCaption}
                            </span>
                          )}
                        </div>
                      </React.Fragment>
                    </ErrorBoundary>
                  )}
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div>
        <div className="d-flex justify-content-center counter">
          {slideIndex + 1}/{content.fieldMultimediaRawField?.list.length}
        </div>
      </div>
    </section>
  );
};

ParagraphMediaGallery.propTypes = {
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.string,
          entity: PropTypes.shape({
            entityBundle: PropTypes.string,
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default ParagraphMediaGallery;
