import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphQuote = ({ content }) => {
  const { fieldImage = null, fieldText, fieldSource } = content;

  const sectionClassNames = classNames({
    "paragraph paragraph-quote": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {fieldImage !== null && (
            <div className="col-2 offset-md-2">
              <ErrorBoundary>
                <Image data={fieldImage.fieldMediaImage} />
              </ErrorBoundary>
            </div>
          )}

          <div
            className={classNames({
              "col-10": fieldImage !== null,
              "col-12 offset-md-2": fieldImage === null,
            })}
          >
            <blockquote className="text">
              <div
                className="quote-wrapper"
                dangerouslySetInnerHTML={{ __html: fieldText }}
              />
              <cite>{fieldSource}</cite>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphQuote.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.object,
    }),
    fieldText: PropTypes.string.isRequired,
    fieldSource: PropTypes.string.isRequired,
  }),
};

ParagraphQuote.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphQuote;
