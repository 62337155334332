import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import AutoSubmit from "../system/auto-submit";
import { useQuery } from "@apollo/client";
import QueryExtendedTeaserViewFilters from "./query-filters.graphql";

// Filters.
import StringFilter from "./string";
import TaxonomyFilter from "./taxonomy";
import useOverviewContext from "../../store/use-overview-context";
import ListFilter from "./list";

/**
 * Renders a set of filters for a specific view.
 */
const Filters = () => {
  const { viewId, currentFilters, setCurrentFilters } = useOverviewContext();

  // Get exposed filters.
  const { data } = useQuery(QueryExtendedTeaserViewFilters, {
    variables: {
      viewId,
    },
  });

  // Get active exposed filters and exclude the bundle filter.
  const defaultBundleFilter = (item) => {
    return item.pluginId === "bundle" && item.field === "type";
  };
  const filters =
    data?.entityById.executable.filters?.filter(
      (filter) => filter.isExposed && !defaultBundleFilter(filter)
    ) || [];

  // Get initialValues for views exposed form.
  const initialValues = {};
  filters.forEach((filter) => {
    initialValues[filter.options.id] = currentFilters[filter.options.id]
      ? currentFilters[filter.options.id]
      : filter.options.value;
  });

  // Fetch nodes when filters change and reset page to 0.
  const filterChangeHandler = (values) => {
    setCurrentFilters(values);
  };

  // If no filters are exposed, return null.
  if (!filters.length) return null;

  return (
    <>
      {filters.length > 0 && (
        <Formik initialValues={initialValues} onSubmit={filterChangeHandler}>
          <Form>
            {/* @todo Only use <Autosubmit> for text fields, feels weird for e.g. selects */}
            <AutoSubmit />
            <div className="row filters">
              {filters.map((item, index) => (
                <div key={index} className="col-16 col-md-3 offset-md-1">
                  <div className="filter">
                    {item.options.expose.label && (
                      <label htmlFor={item.options.id}>
                        {item.options.expose.label}
                      </label>
                    )}
                    {(() => {
                      switch (item.pluginId) {
                        case "string":
                        case "combine":
                          return <StringFilter item={item} />;
                        case "taxonomy_index_tid":
                          return <TaxonomyFilter item={item} />;
                        case "list_field":
                          return <ListFilter item={item} />;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              ))}
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

Filters.propTypes = {
  viewId: PropTypes.string,
  onChange: PropTypes.func,
};

export default Filters;
