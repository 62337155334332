import React from "react";
import { ArrowRight } from "@carbon/icons-react";
import PropTypes from "prop-types";

const CustomArrow = ({ onClick, className }) => (
  <div className={className} onClick={onClick}>
    <ArrowRight size={32} color="white" />
  </div>
);

CustomArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CustomArrow;
