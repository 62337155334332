import React from "react";
import useOverviewContext from "../../store/use-overview-context";
import LoadingIndicator from "@general-components/loading-indicator";

const Loading = () => {
  const { loading } = useOverviewContext();
  if (!loading) return;
  return <LoadingIndicator />;
};

export default Loading;
