import React from "react";
import PropTypes from "prop-types";
import Error from "./error";

const Error500 = ({ error }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-16">
          <h1 className="text-center">500</h1>
          <Error error={error} />
        </div>
      </div>
    </div>
  );
};

Error500.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Error500;
