import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../../image/image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
class TeaserPersonOverview extends Component {
  state = { isOpen: false };

  toggleOverlay = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        document.querySelector("html").style.overflow = "visible";
      });
    } else {
      this.setState({ isOpen: true }, () => {
        document.querySelector("html").style.overflow = "hidden";
      });
    }
  };

  render() {
    return (
      <>
        <article
          className="node node-teaser teaser-person teaser-person-overview"
          data-testid="teaser-person"
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.id}
            destinationRoute={this.props.location.pathname}
          />
          <div className="flex-wrap" onClick={() => this.toggleOverlay()}>
            {this.props.item.fieldImageRawField?.first && (
              <Image
                data={
                  this.props.item.fieldImageRawField.first.entity
                    .fieldMediaImage
                }
                nodeTitle={this.props.item.title}
              />
            )}
            <h3>{this.props.item.title}</h3>

            <div className="role">{this.props.item.fieldRole}</div>

            {this.props.item.fieldEmail && (
              <a className="mail" href={`mailto:${this.props.item.fieldEmail}`}>
                {this.props.item.fieldEmail}
              </a>
            )}

            {this.props.item.fieldPhone && (
              <a
                className="phone"
                href={`tel:${this.props.item.fieldPhone
                  .replace(/ /g, "")
                  .replace(/\([\s\S]*?\)/g, "")}`}
              >
                {this.props.item.fieldPhone}
              </a>
            )}
          </div>
        </article>
        {this.state.isOpen && (
          <div className="person-overlay-bg">
            <div className="person-overlay">
              <div className="container">
                <TeaserPersonOverlay
                  item={this.props.item}
                  toggleOverlay={this.toggleOverlay}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
