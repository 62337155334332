import React from "react";
import { FormattedMessage } from "react-intl";
import { Route } from "react-router-dom";

const Error404 = () => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h1 className="text-center">404</h1>
                <p className="text-center">
                  <FormattedMessage id="error.not_found" />
                </p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default Error404;
