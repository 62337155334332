import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../teaser-base/project/teaser-project";
import ComponentTeaserListCarousel from "./components/component-teaser-list-carousel";
import ComponentTeaserListWrap from "./components/component-teaser-list-wrap";

/**
 * Extend for new content types as needed. Therefore copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
const ParagraphTeaserList = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    [`paragraph-teaser-list-${content.fieldLimit}`]: true,
    "paragraph paragraph-teaser-list-slick":
      content.fieldDisplayMode === "carousel",
    [`paragraph-teaser-list-${content.fieldContentType}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      {content.fieldDisplayMode === "carousel" && (
        <ErrorBoundary>
          <ComponentTeaserListCarousel
            manualNodes={content.fieldNodes}
            id={content.id}
            mode={content.fieldMode}
            limit={content.fieldLimit || 100}
            type={content.fieldContentType}
            pagerFullPage={content.fieldPagerAufVollseiten ? content.id : false}
            pagerFullPageOverviewLink={content.fieldLinkOverview}
            tags={content.fieldTags}
          />
        </ErrorBoundary>
      )}

      {content.fieldDisplayMode === "wrap" && (
        <div className="container">
          <div className="row">
            <ErrorBoundary>
              <ComponentTeaserListWrap
                manualNodes={content.fieldNodes}
                id={content.id}
                mode={content.fieldMode}
                limit={content.fieldLimit}
                type={content.fieldContentType}
                pagerFullPage={
                  content.fieldPagerAufVollseiten ? content.id : false
                }
                pagerFullPageOverviewLink={content.fieldLinkOverview}
                tags={content.fieldTags}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}

      {content.fieldLinkOverview?.title && (
        <div className="container">
          <div className="row">
            <div className="col-16 d-md-flex justify-content-md-end">
              <ErrorBoundary>
                <Link
                  link={content.fieldLinkOverview}
                  className="btn btn-primary more"
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ParagraphTeaserList.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    fieldDisplayMode: PropTypes.oneOf(["wrap", "carousel"]),
    fieldLimit: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldContentType: PropTypes.oneOf(["news", "person", "event", "all"]),
    fieldHeading: PropTypes.string,
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldMode: PropTypes.oneOf(["auto", "manual"]),
    fieldLinkOverview: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldNodes: PropTypes.arrayOf(
      PropTypes.shape({
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserList;
