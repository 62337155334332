import React from "react";
import useOverviewContext from "../../store/use-overview-context";
import { FormattedMessage } from "react-intl";

const Error = () => {
  const { error } = useOverviewContext();

  if (!error) return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-16 col-md-8 offset-md-1">
          <h3>
            <FormattedMessage id="error.general" />
          </h3>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

export default Error;
