import React from "react";
import useOverviewContext from "../../store/use-overview-context";
import ReactPaginate from "react-paginate";

/**
 * Pagination component for displaying page navigation.
 * This component will calculate the number of pages and render a pagination.
 * Place it below the list of nodes in the overview component.
 */
const Pagination = () => {
  const { metaData, currentPage, setCurrentPage } =
    useOverviewContext();

  // Calculate the number of pages.
  const pageCount = Math.ceil(metaData.totalRows / metaData.perPage);

  // Don't render if there is only one page.
  if (pageCount === 0) return null;

  const pageChangeHandler = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <div className="row">
      <div className="col-16 col-md-8 offset-md-4 d-flex justify-content-center">
        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel="→"
            pageRangeDisplayed={5}
            onPageChange={pageChangeHandler}
            pageCount={pageCount}
            initialPage={currentPage}
            previousLabel="←"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;
