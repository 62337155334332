import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Error = ({ error }) => {
  console.error(error);

  return (
    <div className="error-wrapper">
      <p className="text-center">
        <FormattedMessage id="error.general" />
      </p>
      <pre className="text-center">{error.message}</pre>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default Error;
