import { createSlice } from "@reduxjs/toolkit";

const i18nReducerInitialState = {
  currentLanguage: "und",
  translations: [],
};

export const i18nSlice = createSlice({
  name: "i18n",
  initialState: i18nReducerInitialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
  },
});

export const { setCurrentLanguage, setTranslations } = i18nSlice.actions;

export default i18nSlice.reducer;
