import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";

const ImageComponent = ({ content }) => {
  const imageData = content.fieldImageEnhancedRawField.first;
  return (
    <article className="media-image">
      <Image
        data={imageData.entity.fieldMediaImage}
        credit={imageData.entity.fieldCredits}
        caption={
          imageData.defaultCaption === false ? imageData.customCaption : null
        }
      />
    </article>
  );
};

const ParagraphImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-image": true,
    [`format-${content.fieldPosition}`]: true,
    [`size-${content.fieldSize}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      {content.fieldSize === "full-bleed" ? (
        <ErrorBoundary>
          <article className="media-image">
            <ImageComponent content={content} />
          </article>
        </ErrorBoundary>
      ) : (
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-16": true,
                "col-lg-12 offset-lg-2": content.fieldPosition === "centered",
                "col-lg-12": content.fieldPosition === "left",
                "col-lg-12 offset-lg-4": content.fieldPosition === "right",
              })}
            >
              <article className="media-image">
                <ImageComponent content={content} />
              </article>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const paragraphPropTypes = {
  content: PropTypes.shape({
    fieldImageEnhancedRawField: PropTypes.shape({
      first: PropTypes.shape({
        customCaption: PropTypes.string,
        defaultCaption: PropTypes.bool,
        entity: PropTypes.shape({
          fieldCredits: PropTypes.string,
          fieldCaption: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      }),
    }),
    fieldPosition: PropTypes.oneOf(["center", "left", "right"]),
    fieldSize: PropTypes.oneOf(["full-bleed", "standard"]),
  }),
};

ImageComponent.propTypes = paragraphPropTypes;
ParagraphImage.propTypes = paragraphPropTypes;

export default ParagraphImage;
