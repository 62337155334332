import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { Close } from "@carbon/icons-react";
import { withRouter } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import StoryTeaserContent from "./story-teaser-content";
import { activeCloudDetailAction } from "../../cloud-view-action";
import { ContentCloudNodePropTypes } from "../../paragraph-content-cloud";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  activeCloudDetail: reduxStore.contentCloud.activeCloudDetail,
});

class StoryTeaser extends Component {
  constructor(props) {
    super(props);

    /**
     * 0 - 10
     * @type {number}
     */
    const randomNumber = Math.floor(Math.random() * 11);

    this.state = {
      right: false,
      left: false,
      open: false,
      transitionXLeft: 0,
      transitionXRight: 0,
      hover: false,
      randomNumber,
      scrollTargetElement: null,
    };
  }

  storyElement = React.createRef();
  contentWrapper = React.createRef();

  waitForElm = (selector) => {
    // From:
    // https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  setHoverState = () => {
    this.setState({ hover: !this.state.hover });
  };

  openDetail = () => {
    this.setState({ open: true });
  };

  showDetail = (content) => {
    this.props.dispatch(activeCloudDetailAction(content));

    // Move active teaser to detail-view
    let parent = this.storyElement.current.closest(".cloud-teaser"),
      teaserPosition = this.storyElement.current.getBoundingClientRect();

    console.log(this.storyElement.current.getBoundingClientRect());
    document.body.style.setProperty(
      "--active-teaser-left",
      (parent.getBoundingClientRect().left -
        parent.clientWidth / 2 -
        parent.getAttribute("data-col-id") * 16) *
        -1 +
        "px"
    );
    document.body.style.setProperty(
      "--active-teaser-top",
      -teaserPosition.top + teaserPosition.height / 3 + "px"
    );

    // Block Scrolling when Detail is open
    this.waitForElm(".cloud-detail-content").then((elm) => {
      this.setState({
        scrollTargetElement: elm,
      });
      disableBodyScroll(elm);
    });
  };

  closeDetail = () => {
    enableBodyScroll(this.state.scrollTargetElement);
    this.props.dispatch(activeCloudDetailAction(null));
    document.body.style.setProperty("--active-teaser-left", 0);
  };

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    let cloudContentClasses = classNames({
      "cloud-teaser col-lg-2 col-sm-6 col-6": true,
      active:
        this.props.activeCloudDetail &&
        this.props.activeCloudDetail.entityId === this.props.content.entityId,
    });

    const { content } = this.props;

    if (content != null) {
      return (
        <div
          ref={this.storyElement}
          className={cloudContentClasses}
          data-col-id={this.props.index + 1}
        >
          <div
            className="cloud-detail-go-back"
            onClick={() => this.closeDetail(content)}
          >
            <Close size={32} color="black" />
          </div>
          <div
            className="teaser-wrapper"
            onClick={() => this.showDetail(content)}
            style={{
              top: `${this.state.randomNumber * 4.5}vh`,
              left:
                this.props.index === 0
                  ? `${this.state.randomNumber}vw`
                  : `-${this.state.randomNumber * 1.5}vw`,
            }}
          >
            <StoryTeaserContent
              inView={this.props.index !== 0 && this.props.index !== 4}
              content={content}
              hover={this.state.hover}
              setHoverState={this.setHoverState}
              right={this.props.index === 4}
              left={this.props.index === 0}
              contentWrapper={this.contentWrapper}
            />
          </div>
        </div>
      );
    }

    return false;
  }
}

StoryTeaser.propTypes = {
  allContent: PropTypes.arrayOf(PropTypes.shape(ContentCloudNodePropTypes)),
  activeCloudDetail: PropTypes.shape(ContentCloudNodePropTypes),
  content: PropTypes.shape(ContentCloudNodePropTypes),
  dispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(withRouter(StoryTeaser));
