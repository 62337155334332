import React from "react";
import PropTypes from "prop-types";
import Video from "./components/video";

const ParagraphVideo = ({ content }) => {
  return (
    <section className="paragraph paragraph-video">
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-12 offset-lg-2">
            <Video content={content.fieldVideoFile} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const videoThumbnailPropTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({
    urlPath: PropTypes.string,
  }),
}

export const videoFilePropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  mediaFileUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
}

export const videoEmbedPropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  fieldMediaOembedVideo: PropTypes.string,
}

ParagraphVideo.propTypes = {
  content: PropTypes.shape({
    /* fieldVideoFile returns either type MediaEmbeddedVideo or MediaVideo */
    fieldVideoFile: PropTypes.oneOf([

      /* Video File */
      PropTypes.shape(videoFilePropTypes),

      /* Embedded Video (YouTube/Vimeo) */
      PropTypes.shape(videoEmbedPropTypes),
    ]),
  }),
};

export default ParagraphVideo;
