import React from "react";
import Logo from "../../../public/static-assets/logo.svg";

const LoadingIndicator = () => {
    return (
      <div className="loading-indicator-wrap">
        <img src={Logo} alt="Logo" />
      </div>
    );
}

export default LoadingIndicator;
