import React from "react";
import PropTypes from "prop-types";
import { Close } from "@carbon/icons-react";

import Image from "../../image/image";
import { teaserPersonPropTypes } from "./teaser-person";
import ContactInformation from "./components/contact-information";
import SocialInformation from "./components/social-information";

const TeaserPersonOverlay = ({ item, ...props }) => (
  <article className="node node-teaser teaser-person teaser-overlay">
    <div className="row">
      <div className="col-16 col-md-8">
        {item.fieldImageRawField?.first && (
          <Image
            data={item.fieldImageRawField.first.entity.fieldMediaImage}
            nodeTitle={item.title}
            credit={item.fieldImageRawField.first.entity.fieldCredits}
          />
        )}
      </div>
      <div className="col-16 col-md-8">
        {props.toggleOverlay && (
          <div className="close" onClick={() => props.toggleOverlay()}>
            <Close size={32} color="black" />
          </div>
        )}

        <h1>{item.title}</h1>
        <div className="role">{item.fieldRole}</div>

        {item.fieldText && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: item.fieldText
            }}
          />
        )}

        <ContactInformation content={item} />
        <SocialInformation content={item} />
      </div>
    </div>
  </article>
);

TeaserPersonOverlay.propTypes = {
  item: teaserPersonPropTypes,
  toggleOverlay: PropTypes.func
};

export default TeaserPersonOverlay;
