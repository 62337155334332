import React, { useState, useEffect, useRef } from "react";
import { useDebounce } from "react-use";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { Search as SearchIcon, Close } from "@carbon/icons-react";

import searchQuery from "./search.graphql";
import { searchOpenAction } from "../../app-actions";
import ComponentFilterCloud from "../content-base/paragraphs/extended-teaser-list/components/component-filter-cloud";
import LoadingIndicator from "../loading-indicator";

export const searchDebounceTime = 750;

const Search = () => {
  const input = useRef();
  const [searchQueryString, setSearchQueryString] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const searchOpen = useSelector((state) => state.appStore.searchOpen);

  const [getSearchResults, { loading, error, data }] = useLazyQuery(
    searchQuery,
    {
      fetchPolicy: "network-only",
    }
  );

  useDebounce(
    () => {
      if (searchQueryString.length > 0) {
        setIsSearching(true);

        // Fetch Search query
        getSearchResults({
          variables: { searchQueryString: searchQueryString },
        }).then(() => {
          setIsSearching(false);
        });
      }
    },
    searchDebounceTime,
    [searchQueryString]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setFilteredResults(data.solrSearch.documents);
      setSearchResults(data.solrSearch.documents);
    }
  }, [data, error, loading]);

  const filterResults = (filter) => {
    if (filter.id === activeFilter.id) {
      setActiveFilter([]);
      setFilteredResults(searchResults);
    } else {
      setActiveFilter(filter);

      const filteredItems = searchResults.filter((item) => {
        return item.tid.includes(filter.id);
      });

      setFilteredResults(filteredItems);
    }
  };

  const openSearch = (open) => {
    dispatch(searchOpenAction(open));

    if (open) {
      setTimeout(() => {
        if (input.current) {
          input.current.focus();
        }
      }, 500);
    }
  };

  const clickLink = () => {
    dispatch(searchOpenAction(false));

    setSearchResults([]);
    setFilteredResults([]);
    setSearchQueryString("");
  };

  return (
    <div className="search">
      <button
        type="button"
        aria-label={intl.formatMessage({ id: "search.placeholder" })}
        tabIndex="0"
        id="searchIcon"
        onClick={() => openSearch(true)}
      >
        <SearchIcon
          size={24}
          color="black"
          alt={intl.formatMessage({ id: "search.icon" })}
          role="presentation"
        />
      </button>
      {searchOpen && (
        <>
          <div className="search-backdrop" onClick={() => openSearch(false)} />

          <div className="search-container">
            <div className={"inner-wrapper bg-white"}>
              <div
                className={`${
                  searchQueryString.length > 0 ? "searching" : ""
                } search-input`}
              >
                <input
                  ref={input}
                  id="search-input"
                  tabIndex="0"
                  autoComplete="off"
                  placeholder={intl.formatMessage({
                    id: "search.placeholder",
                  })}
                  type="search"
                  name="searchString"
                  onChange={({ target: { value } }) => {
                    setSearchQueryString(value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
                <div className="actions-wrapper">
                  {isSearching ? (
                    <LoadingIndicator width={40} />
                  ) : (
                    <SearchIcon
                      size={24}
                      color="black"
                      alt={intl.formatMessage({ id: "search.icon" })}
                      role="presentation"
                      className="search-icon"
                    />
                  )}

                  <button
                    className="search-close"
                    onClick={() => openSearch(false)}
                    type="button"
                    aria-label={intl.formatMessage({
                      id: "search.close",
                    })}
                    tabIndex="0"
                  >
                    <Close
                      size={24}
                      color="black"
                      id="closeSearch"
                      alt={intl.formatMessage({ id: "search.reset" })}
                    />
                  </button>
                </div>
              </div>
              {searchQueryString.length > 0 && (
                <>
                  {filteredResults && filteredResults.length > 0 ? (
                    <div className="filter-wrapper">
                      <ComponentFilterCloud
                        items={filteredResults.map((item) => ({
                          ...item,
                          customFilter: item.tid.map((tid, index) => ({
                            id: tid,
                            name: item.tname[index],
                          })),
                        }))}
                        filter={["customFilter"]}
                        activeFilter={[activeFilter]}
                        changeActiveFilter={filterResults}
                        wrap={false}
                      />
                    </div>
                  ) : null}

                  {searchResults.length === 0 ? (
                    <div className="no-results">
                      <p>
                        <FormattedMessage id="search.no_results" />
                      </p>
                    </div>
                  ) : null}
                </>
              )}

              {filteredResults &&
                searchQueryString.length > 0 &&
                filteredResults.length > 0 && (
                  <div className="results">
                    <h3 className="result-count">
                      <span className="count">{filteredResults.length}</span>{" "}
                      <FormattedMessage id="search.results" />
                    </h3>

                    <ul className="results-wrapper">
                      {filteredResults.map((result, index) => (
                        <React.Fragment key={index}>
                          <li className="search-result">
                            <Link
                              to={result.url}
                              title={result.title}
                              onClick={(e) => clickLink(e)}
                            >
                              <div className="result-wrap">
                                {result.tname.map((tag, index) => (
                                  <div key={index} className="tag">
                                    {tag}
                                  </div>
                                ))}
                                <div className="title">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: result.title,
                                    }}
                                  />{" "}
                                  <span className="font-arrow">↗</span>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </React.Fragment>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Search.propTypes = {};

export default Search;
