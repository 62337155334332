import React from "react";
import ContentCloud from "@paragraphs/content-cloud/paragraph-content-cloud";
import ParagraphTextAndSpeech from "@paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphAudio from "@paragraphs/audio/paragraph-audio";
import ParagraphVideo from "@paragraphs/video/paragraph-video";
import ParagraphHeroText from "@paragraphs/hero-text/paragraph-hero-text";
import ParagraphClickSuggestions from "@paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserListEvents from "@paragraphs/teaser-list/events/paragraph-teaser-list-events";
import ParagraphTeaserImage from "@paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "@paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "@paragraphs/text/paragraph-text";
import ParagraphMediaGallery from "@paragraphs/media-gallery/paragraph-media-gallery";
import ParagraphImage from "@paragraphs/image/paragraph-image";
import ParagraphFactsAndFigures from "@paragraphs/facts-and-figures/paragraph-facts-and-figures";
import ParagraphTeaserSlider from "@paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphForm from "@paragraphs/webform/paragraph-form";
import ParagraphNewsletterRegistration from "@paragraphs/newsletter-registration/paragraph-newsletter-registration";
import ParagraphCollapse from "@paragraphs/collapse/paragraph-collapse";
import ParagraphDossier from "@paragraphs/dossier/paragraph-dossier";
import ParagraphPageTitle from "@paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "@paragraphs/call-to-action/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "@paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphExtendedTeaserOverviewProjects from "@paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphEventInformation from "@paragraphs/event-information/paragraph-event-information";
import ParagraphPersonInformation from "@paragraphs/person-information/paragraph-person-information";
import ParagraphDownload from "@paragraphs/download/paragraph-download";
import ParagraphQuote from "@paragraphs/quote/paragraph-quote";
import ParagraphTimeline from "@paragraphs/timeline/paragraph-timeline";
import ParagraphContact from "@paragraphs/contact/paragraph-contact";
import ParagraphList from "@paragraphs/list/paragraph-list";
import ParagraphCalendarMonth from "@paragraphs/calendar-month/paragraph-calendar-month";
import ParagraphCalendar from "@paragraphs/calendar/paragraph-calendar";
import ParagraphTeaserList from "@paragraphs/teaser-list/paragraph-teaser-list";
import ParagraphLogosPartners from "@paragraphs/logos-partners/logos-partners";
import ParagraphOverview from "@paragraphs/overview/paragraph-overview";

import Eforkbot from "../../eforkbot/eforkbot";
import { PropTypes } from "prop-types";

const ParagraphSwitch = ({ paragraphItem, nodeContent }) => {
  switch (paragraphItem?.entityBundle) {
    case "content_cloud":
      return <ContentCloud content={paragraphItem} />;
    case "text_and_speech":
      return <ParagraphTextAndSpeech content={paragraphItem} />;
    case "audio":
      return <ParagraphAudio content={paragraphItem} />;
    case "video":
      return <ParagraphVideo content={paragraphItem} />;
    case "hero_text":
      return <ParagraphHeroText content={paragraphItem} />;
    case "click_suggestions":
      return <ParagraphClickSuggestions content={paragraphItem} />;
    case "teaser_list_news":
    case "teaser_list_persons":
    case "teaser_list_pages":
      return <ParagraphTeaserList content={paragraphItem} />;
    case "teaser_list_events":
      return <ParagraphTeaserListEvents content={paragraphItem} />;
    case "teaser_image_text":
      return <ParagraphTeaserImage content={paragraphItem} />;
    case "section_heading":
      return <ParagraphSectionheading content={paragraphItem} />;
    case "text":
      return <ParagraphText content={paragraphItem} />;
    case "media_gallery":
      return <ParagraphMediaGallery content={paragraphItem} />;
    case "image":
      return <ParagraphImage content={paragraphItem} />;
    case "facts_and_figures":
      return <ParagraphFactsAndFigures content={paragraphItem} />;
    case "teaser_slider":
      return <ParagraphTeaserSlider content={paragraphItem} />;
    case "form":
      return <ParagraphForm content={paragraphItem} />;
    case "newsletter_registration":
      return <ParagraphNewsletterRegistration content={paragraphItem} />;
    case "collapse":
      return <ParagraphCollapse content={paragraphItem} />;
    case "chatbot":
      return <Eforkbot />;
    case "dossier":
      return <ParagraphDossier content={paragraphItem} />;
    case "page_title":
      return <ParagraphPageTitle content={paragraphItem} />;
    case "call_to_action":
      return <ParagraphCallToAction content={paragraphItem} />;
    case "erweiterte_teaseruebersicht":
    case "teaseruebersicht_events":
    case "teaseruebersicht_personen": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverview
            content={paragraphItem}
            nodes={nodes}
          />
        );
      }

      return <ParagraphExtendedTeaserOverview content={paragraphItem} />;
    }
    case "teaseruebersicht_projekte": {
      const nodes = {};

      if (paragraphItem.fieldManuelleSortierung) {
        nodes.loading = false;
        nodes.nodeQuery = {};
        nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
          (node) => node.entity
        );

        return (
          <ParagraphExtendedTeaserOverviewProjects
            content={paragraphItem}
            nodes={nodes}
          />
        );
      }

      return (
        <ParagraphExtendedTeaserOverviewProjects content={paragraphItem} />
      );
    }
    case "event_information":
      return (
        <ParagraphEventInformation
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "person_information":
      return (
        <ParagraphPersonInformation
          content={paragraphItem}
          nodeContent={nodeContent}
        />
      );
    case "download":
      return <ParagraphDownload content={paragraphItem} />;
    case "quote":
      return <ParagraphQuote content={paragraphItem} />;
    case "timeline":
      return <ParagraphTimeline content={paragraphItem} />;
    case "contact":
      return <ParagraphContact content={paragraphItem} />;
    case "list":
      return <ParagraphList content={paragraphItem} />;
    case "calendar_month":
      return <ParagraphCalendarMonth content={paragraphItem} />;
    case "calendar":
      return <ParagraphCalendar content={paragraphItem} />;
    case "logos_partners":
      return <ParagraphLogosPartners content={paragraphItem} />;
    case "overview_news":
    case "overview_persons":
    case "overview_projects":
    case "overview_events":
      return <ParagraphOverview content={paragraphItem} />;
    default:
      return null;
  }
};

ParagraphSwitch.propTypes = {
  paragraphItem: PropTypes.object,
  nodeContent: PropTypes.object,
};

export default ParagraphSwitch;
