import React from "react";
import ErrorBoundary from "../../../../../error-boundary";
import PropTypes from "prop-types";
import VideoEmbedded from "./video-embedded";
import VideoFile from "./video-file";

const Video = ({ content }) => {
  return (
    <div className="video-wrapper">
      {(() => {
        switch (content.entityBundle) {
          case "embedded_video":
            return (
              <ErrorBoundary>
                <VideoEmbedded content={content} />
              </ErrorBoundary>
            );
          case "video":
            return (
              <ErrorBoundary>
                <VideoFile content={content} />
              </ErrorBoundary>
            );
          default:
            return false;
        }
      })()}
    </div>
  );
};

Video.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Video;
