import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const StringFilter = ({ item }) => {
  return (
    <Field
      id={item.options.id}
      name={item.options.id}
      type="text"
      placeholder={item.options.expose.placeholder}
    />
  );
};

StringFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
      }),
    }),
  }),
};

export default StringFilter;
