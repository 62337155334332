import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CustomCursor from "./custom-cursor";
import classNames from "classnames";

let timeOutText;

const mapStateToProps = (reduxStore) => ({
  cloudView: reduxStore.cloudView,
});

const ParagraphTextAndSpeech = ({ content }) => {
  const formattedText = content.fieldText.replace(/<[^>]*>?/gm, "");

  const player = useRef();
  const textContainer = useRef();

  const [initialText, setInitialText] = useState(formattedText);
  const [replaceText, setReplaceText] = useState("");
  const [hover, setHover] = useState(false);

  const textTransform = () => {
    const duration = (player.current.duration * 1000) / formattedText.length;

    for (let i = 0; i < initialText.length; i++) {
      timeOutText = setTimeout(() => {
        setReplaceText((prev) => {
          return prev + formattedText[i];
        });
        setInitialText((prev) => prev.slice(1));

        if (i === formattedText.length - 1 || player.current.paused) {
          setInitialText(formattedText);
          setReplaceText("");
        }
      }, i * duration);
    }
  };

  const toggle = () => {
    if (player.current.paused) {
      player.current.play();
      textTransform();
    } else {
      setInitialText(formattedText);
      setReplaceText("");

      player.current.pause();
      player.current.currentTime = 0;

      while (timeOutText--) {
        window.clearTimeout(timeOutText);
      }
    }
  };

  const hoverHandler = () => {
    setHover((prev) => !prev);
  };

  return (
    <div className="paragraph paragraph-text-and-speech">
      {hover && <CustomCursor playing={!player.current?.paused} />}
      <div className="container">
        <div className="row">
          <div
            ref={textContainer}
            onMouseEnter={hoverHandler}
            onMouseLeave={hoverHandler}
            onClick={toggle}
            className={classNames({
              "offset-lg-2 col-lg-8": content.fieldSource,
              "offset-lg-1 col-lg-10": !content.fieldSource,
              "text-container": true,
              "hover-cursor": hover,
              playing: !player.current?.paused,
            })}
          >
            <span className="text-wrapper">
              {replaceText}
              <span className="initial-text">{initialText}</span>
            </span>
            {content.fieldSource && (
              <div className="source">{content.fieldSource}</div>
            )}
          </div>
          <audio ref={player} src={content.fieldAudio.mediaFileUrl.path} />
        </div>
      </div>
    </div>
  );
};

ParagraphTextAndSpeech.propTypes = {
  content: PropTypes.shape({
    fieldAudio: PropTypes.shape({
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.string,
    fieldSource: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(ParagraphTextAndSpeech);
