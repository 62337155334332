const calendarMonthReducerDefaultState = {
    months: [],
  },
  calendarMonthReducer = (state = calendarMonthReducerDefaultState, action) => {
    switch (action.type) {
      case "CALENDAR_MONTH_SET_MONTH_ACTION": {
        const configs = state.months.filter(
          (item) => item.id !== action.config.id
        );

        return Object.assign({}, state, {
          months: [...configs, action.config],
        });
      }
      default:
        return state;
    }
  };

export { calendarMonthReducerDefaultState, calendarMonthReducer };
