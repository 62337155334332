import { useState } from "react";
import { useQuery } from "@apollo/client";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";

/**
 * Custom react hook that returns either manual nodes or auto nodes as state by given variables.
 * @param {array} manualNodes List of manual nodes directly selected in Drupal.
 * @param {object} options 
 * @param {number} options.limit Amount of nodes to be fetched at a time.
 * @param {string} options.type Filter for content type of fetched nodes (machine name).
 * @param {array} options.tags List of tags to filter the results of fetched nodes.
 * @returns 
 */
const useTeaserQuery = (manualNodes, options) => {
  const [loading, setLoading] = useState(options.mode === "auto");
  const [nodes, setNodes] = useState(
    options.mode === "manual" ? manualNodes : []
  );

  useQuery(teaserNodeQueryFilterTag, {
    skip: options.mode === "manual",
    variables: {
      limit: options.limit || 100,
      type: options.type,
      tag: options.tags.map((item) => item.id),
      filterTagEnabled: options.tags.length > 0,
    },
    onCompleted: (data) => {
      setNodes(data.entityQuery?.items);
      setLoading(false);
    },
  });

  return { nodes, loading };
};

export default useTeaserQuery;
