import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const SocialInformation = ({ content }) => {
  const socialData = [
    {
      labelId: "contact.instagram",
      href: content.fieldInstagram?.uri?.path,
    },
    {
      labelId: "contact.linkedin",
      href: content.fieldLinkedin?.uri?.path,
    },
    {
      labelId: "contact.facebook",
      href: content.fieldFacebook?.uri?.path,
    },
  ].filter((item) => item.href);

  return (
    <div className="social-infos-wrapper">
      {socialData.map((item) => (
        <div key={item.labelId} className="social-info">
          <a href={item.href} className="label">
            <FormattedMessage id={item.labelId} />
          </a>
        </div>
      ))}
    </div>
  );
};

SocialInformation.propTypes = {
  content: PropTypes.shape({
    fieldLinkedin: PropTypes.object,
    fieldInstagram: PropTypes.object,
    fieldFacebook: PropTypes.object,
  }),
};

export default SocialInformation;
