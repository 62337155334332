import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "../../tags/tags";

const TeaserNews = ({ item, pagerFullPage = false }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-news">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        {item.fieldImage && (
          <Image
            data={item.fieldImage.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}
        <span className="top-line">
          <Tags tags={item.fieldTags} />
        </span>
        <h3>{item.title}</h3>
        {item.fieldText && (
          <div
            className="teaser text"
            dangerouslySetInnerHTML={{
              __html: item.fieldText.trim().substring(0, 250) + "...",
            }}
          />
        )}
      </Link>
    </article>
  );
};

export const teaserNewsPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAtRawField: PropTypes.shape({
    first: PropTypes.shape({
      value: PropTypes.string,
      publishedAtOrNow: PropTypes.string,
    }),
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldText: PropTypes.string,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserNews.propTypes = {
  item: teaserNewsPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserNews;
