import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { List, Grid } from "@carbon/icons-react";
import { FormattedMessage } from "react-intl";

import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";
import ComponentFilterCloud from "../extended-teaser-list/components/component-filter-cloud";
import {
  timelineSetActiveFilterAction,
  timelineRemoveActiveFilterAction,
} from "./actions-timeline";
import { useLazyQuery } from "@apollo/client";

/*
 * @todo: Pager.
 */
const ParagraphTimeline = ({ content }) => {
  // config related state
  const [activeFilter, setActiveFilter] = useState({});
  const [displaySwitch, setDisplaySwitch] = useState("grid");

  // node related state
  const [nodes, setNodes] = useState([]);
  const [totalNodes, setTotalNodes] = useState(0);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  // reduce tags to simple array
  const tags =
    (!!content.fieldTags.length && content.fieldTags.map((tag) => tag.id)) ||
    [];

  // store query into getNodes function for later use
  const [getNodes] = useLazyQuery(teaserNodeQueryFilterTag, {
    variables: {
      limit: content.fieldLimit,
      type: ["news"],
      offset,
      status: ["1"],
      tag: tags,
      filterTagEnabled: tags.length > 0,
    },
    onCompleted: (data) => {
      setNodes((prevNodes) => [
        ...prevNodes,
        ...(data.entityQuery?.items || []),
      ]);
      setTotalNodes(data.entityQuery?.total);
    },
    onError: (err) => {
      console.error(err);
      setError(err);
    },
  });

  // call query on mount and on every offset-change
  useEffect(() => {
    getNodes({ variables: { offset } });
  }, [getNodes, offset]);

  const changeActiveFilter = (filter) => {
    if (filter.id === activeFilter.id) {
      setActiveFilter({});
      dispatch(timelineRemoveActiveFilterAction(content.id));
    } else {
      setActiveFilter(filter);
      dispatch(
        timelineSetActiveFilterAction({
          timeLineId: content.id,
          config: filter,
        })
      );
    }
  };

  const loadMoreEnabled =
    content.fieldLoadMore && nodes?.length > 0 && nodes?.length < totalNodes;

  if (error) {
    return <div className="container">{error.message}</div>;
  }

  if (!nodes.length) {
    return <LoadingIndicator />;
  }

  const sectionClassNames = classNames({
    "paragraph paragraph-timeline": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        {(content.fieldFilterCloud || content.fieldDisplayModeToggle) && (
          <div className="row timeline-filter">
            <div className="filter col-16 col-md-14">
              {/* Note: Currently only works with filter={["fieldTags"]}, no custom filters!
                    In the timeline module, every filter change triggers a new (filtered) query, so the filters
                    would be needed to be variable in the graphql query (which does not work).
                  */}
              {content.fieldFilterCloud && (
                <ComponentFilterCloud
                  items={nodes}
                  filter={["fieldTags"]}
                  activeFilter={[activeFilter]}
                  changeActiveFilter={changeActiveFilter}
                  wrap={false}
                />
              )}
            </div>
            <div className="list-switch col-16 col-md-2 d-flex justify-content-end">
              {content.fieldDisplayModeToggle && (
                <>
                  <List
                    size={32}
                    color="black"
                    className={classNames({
                      active: displaySwitch === "list ",
                    })}
                    onClick={() => setDisplaySwitch("list")}
                  />
                  <Grid
                    size={32}
                    color="black"
                    className={classNames({
                      active: displaySwitch === "grid ",
                    })}
                    onClick={() => setDisplaySwitch("grid")}
                  />
                </>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div
            className={classNames({
              "col-16": true,
              "col-lg-14 offset-lg-1": displaySwitch === "grid",
            })}
          >
            <div className="row">
              {displaySwitch === "grid" ? (
                <>
                  <div className="timeline col-1 d-none d-lg-flex flex-lg-column">
                    <div className="top">
                      <span>
                        <FormattedMessage id="timeline.now" />
                      </span>
                    </div>

                    <div className="bottom">
                      <span>
                        <FormattedMessage id="timeline.earlier" />
                      </span>
                    </div>
                  </div>

                  <div className="col-16 col-lg-6 offset-lg-1">
                    {nodes
                      .filter((item, index) => (index + 1) % 2 !== 0)
                      .map((item) => (
                        <TeaserBase
                          item={item}
                          key={item.id}
                          viewMode={"timeline"}
                        />
                      ))}
                  </div>

                  <div className="col-16 col-lg-6 offset-lg-1 timeline-column-1">
                    {nodes
                      .filter((item, index) => (index + 1) % 2 === 0)
                      .map((item) => (
                        <TeaserBase
                          item={item}
                          key={item.id}
                          viewMode={"timeline"}
                        />
                      ))}
                  </div>
                </>
              ) : (
                <div className="col-16">
                  {nodes.map((item) => (
                    <TeaserBase
                      item={item}
                      key={item.id}
                      viewMode={"timeline-list"}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {loadMoreEnabled && (
          <div className="row">
            <div className="col-16 d-flex justify-content-end justify-content-md-center">
              <div
                className="btn btn-primary"
                onClick={() =>
                  setOffset((prevOffset) => prevOffset + content.fieldLimit)
                }
              >
                <span>
                  <FormattedMessage id="load_more" />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldFilterCloud: PropTypes.bool,
    fieldDisplayModeToggle: PropTypes.bool,
    fieldLoadMore: PropTypes.bool,
    fieldLimit: PropTypes.number,
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }),
};

export default ParagraphTimeline;
