import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const ContactInformation = ({ content }) => {
  const contactData = [
    {
      labelId: "contact.phone",
      value: content.fieldPhone,
      href: `tel:${content.fieldPhone
        ?.replace(/ /g, "")
        .replace(/\([\s\S]*?\)/g, "")}`,
    },
    {
      labelId: "contact.mobile",
      value: content.fieldMobile,
      href: `tel:${content.fieldMobile
        ?.replace(/ /g, "")
        .replace(/\([\s\S]*?\)/g, "")}`,
    },
    {
      labelId: "contact.email",
      value: content.fieldEmail,
      href: `mailto:${content.fieldEmail}`,
    },
    {
      labelId: "contact.website",
      value: content.fieldWebsite?.uri?.path,
      href: content.fieldWebsite?.uri?.path,
    },
  ].filter((item) => item.value);

  return (
    <div className="contact-infos-wrapper">
      {contactData.map((item) => (
        <div key={item.labelId} className="contact-info">
          <span className="label">
            <FormattedMessage id={item.labelId} />
          </span>
          <span className="value">
            <a href={item.href}>{item.value}</a>
          </span>
        </div>
      ))}
    </div>
  );
};

ContactInformation.propTypes = {
  content: PropTypes.shape({
    fieldPhone: PropTypes.string,
    fieldMobile: PropTypes.string,
    fieldEmail: PropTypes.string,
    fieldWebsite: PropTypes.object,
  }),
};

export default ContactInformation;
