import React from "react";
import PropTypes from "prop-types";

const Tags = ({ tags }) => {
  tags = tags?.filter((tag) => tag !== null) || [];

  if (!tags.length) return;

  return (
    <div className="tags-wrapper">
      {tags.map((tag, i) => (
        <span key={i} className="tag">
          {tag.name}
        </span>
      ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default Tags;
