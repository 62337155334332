import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import ContentBase from "../../content-base";

import { ArrowDown } from "@carbon/icons-react";

const ParagraphCollapse = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-collapse": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-8 offset-lg-4">
            <Accordion allowZeroExpanded={true}>
              {content.fieldCollapseElements.map((item, index) => (
                <ErrorBoundary key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {item.fieldHeading}
                        <div className="toggle-indicator">
                          <ArrowDown size={32} color="black" />
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {item.fieldCollapseContent &&
                        <ContentBase
                          content={item.fieldCollapseContent}
                          nodeContent={false}
                        />
                      }
                    </AccordionItemPanel>
                  </AccordionItem>
                </ErrorBoundary>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCollapse.propTypes = {
  content: PropTypes.shape({
    fieldCollapseElements: PropTypes.arrayOf(
      PropTypes.shape({
        fieldHeading: PropTypes.string,
        fieldCollapseContent: PropTypes.array,
      })
    ),
  }),
};

export default ParagraphCollapse;
