import React, { useEffect, useRef } from "react";
import useOverviewContext from "../../store/use-overview-context";
import PropTypes from "prop-types";

/**
 * Component for implementing endless scrolling behavior.
 * This function uses the Apollo fetchMore function to add more data to the list of nodes.
 * Place it below the list of nodes in the overview component.
 * @returns {JSX.Element} The EndlessScroll component.
 */
const EndlessScroll = ({ rootMargin = 3000 }) => {
  // Get relevant values and functions from the OverviewContext.
  const { metaData, nodes, loading, fetchMore } = useOverviewContext();

  const scrollRef = useRef();

  const fetchMoreResults = () => {
    const newPage = Math.ceil(nodes.length / metaData.perPage);

    /**
     * The fetchMore function is provided by Apollo Client.
     * It will fetch more data, updates the returning variables from the useQuery 
     * hook (data, loading, error) and it will update the Apollo cache.
     */
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          entityById: {
            ...prev.entityById,
            executable: {
              ...prev.entityById?.executable,
              execute: {
                ...prev.entityById?.executable.pager,
                ...prev.entityById?.executable?.execute,
                rows: [
                  ...(prev.entityById?.executable?.execute?.rows || []),
                  ...(fetchMoreResult.entityById?.executable?.execute?.rows ||
                    []),
                ],
                ...prev.entityById?.executable.totalRows,
              },
            },
          },
        });
      },
      variables: { page: newPage },
    });

    
  };

  useEffect(() => {
    const triggerElement = scrollRef.current;

    // Check if the element is intersecting with the viewport.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          !loading && // Prevent fetching when loading.
          nodes.length < metaData.totalRows // Prevent fetching when all nodes are fetched.
        ) {
          fetchMoreResults();
        }
      },
      { threshold: 1.0, rootMargin: `${rootMargin}px` }
    );

    if (triggerElement) {
      observer.observe(triggerElement);
    }

    // Clean up function.
    return () => {
      if (triggerElement) {
        observer.disconnect();
      }
    };
  });

  return <div ref={scrollRef} />;
};

EndlessScroll.propTypes = {
  /**
   * The number in pixels that the intersection box will be increased.
   * A higher value will trigger the refetching function earlier.
   */
  rootMargin: PropTypes.number
};

export default EndlessScroll;
