import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDownRight } from "@carbon/icons-react";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphClickSuggestions = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-click-suggestions": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <h2>{content.fieldHeading}</h2>
          </div>
          <div className="col-16">
            <div className="row">
              <div className="col-16 col-md-6 offset-md-1">
                <ul>
                  {content.fieldLinks.map((item, index) => (
                    <React.Fragment key={index}>
                      {Math.abs((index + 1) % 2) === 1 && (
                        <li>
                          <ArrowDownRight size={32} color="black" />
                          <ErrorBoundary>
                            <Link link={item} />
                          </ErrorBoundary>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </div>

              <div className="col-16 col-md-6">
                <ul>
                  {content.fieldLinks.map((item, index) => (
                    <React.Fragment key={index}>
                      {(index + 1) % 2 === 0 && (
                        <li>
                          <ArrowDownRight size={32} color="black" />
                          <ErrorBoundary>
                            <Link link={item} />
                          </ErrorBoundary>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLinks: PropTypes.arrayOf(LinkPropType),
  }),
};

export default ParagraphClickSuggestions;
