import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";

import LoadingIndicator from "../../../loading-indicator";

const ParagraphNewsletterRegistration = ({ content }) => {
  const intl = useIntl();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitHandler = (values, actions) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setError(false);
      setSuccess(true);
      actions.setSubmitting(false);
    }, 750);
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-newsletter-registration": true,
  });

  return (
    <section className={sectionClassNames}>
      {loading && <LoadingIndicator />}

      <div className="container">
        <div className="row">
          <div className="col-16">
            {error && !error.message && (
              <div
                className="alert alert-danger"
                role="region"
                aria-live="polite"
              >
                <FormattedMessage id="newsletter.error" />
              </div>
            )}

            {error && error.message && (
              <div
                className="alert alert-danger"
                role="region"
                aria-live="polite"
              >
                {error.message}
              </div>
            )}

            {success && (
              <div
                className="alert alert-success"
                role="region"
                aria-live="polite"
              >
                <FormattedMessage id="newsletter.success" />
              </div>
            )}
          </div>
          <div className="col-16 col-md-8">
            <h3>{content.fieldHeading}</h3>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText,
              }}
            />
          </div>
          <div className="col-16 col-md-8">
            <Formik
              onSubmit={submitHandler}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                email: "",
                consent: false,
              }}
              validationSchema={Yup.lazy(() =>
                Yup.object().shape({
                  email: Yup.string()
                    .email()
                    .required(
                      intl.formatMessage({ id: "newsletter.form.email_error" })
                    ),
                  consent: Yup.bool().oneOf(
                    [true],
                    intl.formatMessage({ id: "newsletter.form.consent_error" })
                  ),
                })
              )}
            >
              {({ errors, touched }) => (
                <Form noValidate>
                  <div className="form-row">
                    <div className="col-16 form-group">
                      <label htmlFor="email">
                        <FormattedMessage id="newsletter.form.email_label" />
                      </label>
                      <Field
                        aria-label={intl.formatMessage({
                          id: "newsletter.form.email_label",
                        })}
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="E-Mail"
                      />
                      {errors.email && touched.email ? (
                        <div
                          className="form-error text-danger"
                          role="region"
                          aria-live="polite"
                        >
                          {errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-16 form-check">
                      <Field
                        type="checkbox"
                        name="consent"
                        id="consent"
                        aria-labelledby="form-check-label"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="consent">
                        <FormattedMessage
                          id="newsletter.form.consent_label"
                          values={{ privacyPolicyUrl: "aaabasdb" }}
                        />
                        {errors.consent && touched.consent ? (
                          <span
                            className="form-error text-danger"
                            role="region"
                            aria-live="polite"
                          >
                            {errors.consent}
                          </span>
                        ) : null}
                      </label>
                    </div>
                  </div>

                  <div className="form-row actions">
                    <div className="col-16 d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="newsletter.form.submit" />
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphNewsletterRegistration.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.string,
  }),
};

export default ParagraphNewsletterRegistration;
