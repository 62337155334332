import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import { trunc } from "../../../lib/shorten-text";

import EditButton from "../../../backend/edit-button";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";
import Tags from "../../tags/tags";

/**
 * @todo use "FieldTime"
 */
const TeaserEvent = (props) => {
  const intl = useIntl();
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  /**
   * @todo use "FieldTime" (!!!)
   */
  const date = {
    value: props.item.fieldDate.value,
    endValue: props.item.fieldDate.endValue,
  };

  return (
    <article
      className={`node node-${props.item.id} node-teaser node-teaser-default teaser-event col-16`}
    >
      <EditButton
        adminApp={adminApp}
        entityId={props.item.id}
        destinationRoute={location.pathname}
      />
      <div className="row">
        <div className="col-16 col-lg-3">
          {props.item.fieldImage !== null && (
            <Image
              data={props.item.fieldImage.fieldMediaImage}
              nodeTitle={props.item.title}
            />
          )}
        </div>
        <div className="col-16 col-lg-3 d-none d-lg-block">
          <div className="meta-date top-line">
            <span>{moment.utc(date.value).local().format("dddd")}</span>
            {moment.utc(date.value).local().format("HH:mm") !== "00:00" && (
              <span>{moment.utc(date.value).local().format("HH:mm")} Uhr</span>
            )}
          </div>
          <div className="date">
            <span>{moment.utc(date.value).local().format("DD.MM.YYYY")}</span>
          </div>
          <div className="location">{props.item.fieldLocation}</div>
          {date.endValue && props.item.fieldShowDuration && (
            <div className="duration">
              Dauer{" "}
              {Math.round(
                moment
                  .duration(
                    moment
                      .utc(date.endValue)
                      .local()
                      .diff(moment.utc(date.value).local())
                  )
                  .asHours()
              )}{" "}
              Std.
            </div>
          )}
        </div>
        <div className="col-16 d-block d-lg-none mobile-header">
          <div className="row">
            <div className="col-8">
              <span>
                {moment.utc(date.value).local().format("dd, DD.MM.YYYY")}
              </span>
            </div>
            <div className="col-8 d-flex align-items-end">
              <span>{props.item.fieldLocation}</span>
              {moment.utc(date.value).local().format("HH:mm") !== "00:00" && (
                <span>
                  {moment.utc(date.value).local().format("HH:mm")} Uhr
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-16 col-lg-6">
          <Tags tags={props.item.fieldTags} />
          <h3>{props.item.title}</h3>
          <div
            className="text teaser-text d-none d-lg-block"
            dangerouslySetInnerHTML={{
              __html: `${trunc(
                props.item.fieldText,
                150,
                true
              )} <a href="{props.item.entityUrl ? props.item.entityUrl.path : props.item.path.alias}">${intl.formatMessage(
                { id: "read_more" }
              )}</a>`,
            }}
          />
        </div>
        <div className="d-none col-16 col-lg-4 justify-content-lg-end d-lg-flex align-items-lg-baseline">
          <a
            href={getEncodedIcalUrl(
              props.item.fieldDate,
              props.item.fieldLocation,
              props.item.title,
              props.item.fieldText
            )}
            className="btn btn-primary"
          >
            <FormattedMessage id="event.remember" />
          </a>
          {props.item.fieldTicket && (
            <a
              href={props.item.fieldTicket.uri.path}
              className="btn btn-primary"
            >
              <FormattedMessage id="event.ticket" />
            </a>
          )}
        </div>
      </div>
    </article>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldLocation: PropTypes.string,
  fieldShowDuration: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicket: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  item: teaserEventPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TeaserEvent;
