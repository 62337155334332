import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  PlayFilledAlt,
  PauseFilled,
  Rewind_10,
  VolumeUp,
} from "@carbon/icons-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { restHostBackend } from "./../../../../config";

const ParagraphAudio = ({ content }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [prevVolume, setPrevVolume] = useState(1);

  const player = useRef();

  const timeUpdateListener = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const getTime = (time) => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      );
    }

    return null;
  };

  const goBack = (seconds) => {
    setCurrentTime((prev) => {
      let newTime = 0;
      if (prev - seconds > 0) {
        newTime = prev - seconds;
      }
      player.current.currentTime = newTime;
      return newTime;
    });
  };

  const play = () => {
    player.current.play();
  };

  const pause = () => {
    player.current.pause();
  };

  const volumeHandler = (e) => {
    setVolume(e.target.value);
    player.current.volume = e.target.value;
  };

  const toggleMute = () => {
    setVolume((prev) => {
      if (prev > 0) {
        setPrevVolume(prev);
        player.current.volume = 0;
        return 0;
      } else {
        player.current.volume = prevVolume;
        return prevVolume;
      }
    });
  };

  useEffect(() => {
    const audioPlayer = player.current;
    setDuration(audioPlayer.duration);
    // event listener if currentTime changed
    audioPlayer.addEventListener("timeupdate", (e) => timeUpdateListener(e));

    // update time display when metadata has loaded
    audioPlayer.addEventListener("loadedmetadata", (e) =>
      timeUpdateListener(e)
    );

    // remove event listeners on unmount
    return () => {
      if (audioPlayer) {
        audioPlayer.removeEventListener("timeupdate", (e) =>
          timeUpdateListener(e)
        );
        audioPlayer.removeEventListener("loadedmetadata", (e) =>
          timeUpdateListener(e)
        );
      }
    };
  }, [player]);

  const sectionClassNames = classNames({
    "paragraph paragraph-audio": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {content.fieldFile.fieldPreviewImage?.fieldMediaImage && (
            <div className="col-sm-12 col-md-4 image-section">
              <img src={content.fieldFile.fieldPreviewImage?.fieldMediaImage.style.url} alt="" />
            </div>
          )}
          <div className="col-sm-12 audio-section">
            {Boolean(content.fieldTitle) && <h2>{content.fieldTitle}</h2>}
            <div className="audio">
              <audio
                src={`${restHostBackend}${content.fieldFile.mediaFileUrl.path}`}
                aria-controls="audio-controls"
                ref={player}
              ></audio>
              <div className="time">
                <div className="current">{getTime(currentTime)}</div>
                <div className="duration">{getTime(duration)}</div>
              </div>
              <div className="progress-bar">
                <div className="bar">
                  <div
                    className="fill"
                    style={{
                      width: `${(100 / duration) * currentTime}%`,
                    }}
                  />
                </div>
              </div>
              <div id="audio-controls" className="button">
                <div className="function">
                  <button tabIndex={0} onClick={play}>
                    <PlayFilledAlt size={32} color="black" />
                  </button>
                  <button tabIndex={0} onClick={pause}>
                    <PauseFilled size={32} color="black" />
                  </button>
                  <button tabIndex={0} onClick={() => goBack(10)}>
                    <Rewind_10 size={32} color="black" />
                  </button>
                </div>
                <div className="volume-input-wrapper">
                  <a onClick={toggleMute}>
                    <VolumeUp size={32} color="black" />
                  </a>
                  <input
                    type="range"
                    name="volume"
                    className="volume-input"
                    onChange={volumeHandler}
                    value={volume}
                    step={0.1}
                    min={0}
                    max={1}
                  />
                </div>
              </div>
            </div>
            {Boolean(content.fieldFile.name) &&
              Boolean(content.fieldFile.fieldDescription) && (
                <div className="info">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <Accordion allowZeroExpanded>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="top">
                                <div className="text">
                                  {content.fieldFile.name}
                                </div>
                                <div className="icon"></div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content.fieldFile.fieldDescription,
                              }}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAudio.propTypes = {
  content: PropTypes.shape({
    fieldTitle: PropTypes.string,
    fieldFile: PropTypes.shape({
      name: PropTypes.string,
      fieldDescription: PropTypes.string,
      fieldPreviewImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default ParagraphAudio;
