import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@carbon/icons-react";

const EditButton = (props) => {
  if (props.adminApp) {
    return (
      <a
        href={`/node/${props.entityId}/edit?destination=${props.destinationRoute}`}
        className="admin-link admin-edit-link"
      >
        <Edit size={32} color="black" />
      </a>
    );
  }

  return false;
};

EditButton.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  destinationRoute: PropTypes.string.isRequired,
};

export default EditButton;
