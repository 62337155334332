import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useCanonicalOrRedirect } from "./use-canonical-or-redirect";
import { useI18n } from "../i18n/use-i18n";
import { useSelector } from "react-redux";

const useNodeQuery = (query) => {
  // get requested location from react router
  const location = useLocation();

  // get information about microsite and frontpage from redux store
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);
  const microSiteFrontPage = useSelector(
    (reduxStore) => reduxStore.appStore.microSiteFrontPage
  );

  const reducedPath =
    location.pathname === "/" && microSite && microSiteFrontPage !== ""
      ? microSiteFrontPage
      : location.pathname;

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  const { data, loading, error } = useQuery(query, {
    variables: {
      path: reducedPath,
    },
  });

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  return { entity, loading, error };
};

export default useNodeQuery;
