import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import ParagraphExtendedTeaserOverview from "./../paragraph-extended-teaser-overview";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * This exists because Projects are using an own GraphQL query. This approach
 * should be used for all custom queries in extended teasers to keep things
 * clean.
 */
class ParagraphExtendedTeaserOverviewProjects extends Component {
  render() {
    return (
      <ParagraphExtendedTeaserOverview
        content={this.props.content}
        nodes={this.props.nodes}
        skipQuery={true}
      />
    );
  }
}

ParagraphExtendedTeaserOverviewProjects.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entityBundle: PropTypes.oneOf(["filtertext_text", "filtertext_filter"]),
        fieldFilterTextText: PropTypes.string,
        fieldFilter: PropTypes.shape({
          label: PropTypes.string,
          id: PropTypes.string,
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
        fieldFilterTextText: PropTypes.string,
        fieldFilterMultiple: PropTypes.arrayOf(
          PropTypes.shape({
            targetId: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) =>
      !props.content.fieldTypExtended || props.content.fieldManuelleSortierung,
    options: (props) => ({
      variables: {
        limit: 1000,
        type: [props.content.fieldTypExtended],
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
        filterTagEnabled: props.content.fieldSchlagwort.length > 0,
        sortField: "title",
        sortDirection: "ASC"
      },
    }),
  })(ParagraphExtendedTeaserOverviewProjects)
);
