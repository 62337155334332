import React from "react";
import PropTypes from "prop-types";
import Image from "../../../../image/image";
import { Link } from "react-router-dom";

const DossierElement = ({ content }) => {
  const { fieldImage, fieldTitle, fieldText, fieldReferencedContent } = content;

  const imageData =
    fieldImage?.fieldMediaImage ||
    fieldReferencedContent.fieldImage?.fieldMediaImage;

  const title = fieldTitle || fieldReferencedContent.title;
  const text = fieldText || fieldReferencedContent.fieldText;

  return (
    <article className="dossier-element">
      <Link to={fieldReferencedContent.url.path}>
        {imageData && <Image data={imageData} />}
        <div className="teaser-body">
          {title && <h3>{title}</h3>}
          {text && (
            <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
          )}
        </div>
      </Link>
    </article>
  );
};

DossierElement.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.object,
    fieldText: PropTypes.string,
    fieldTitle: PropTypes.string,
    fieldReferencedContent: PropTypes.shape({
      title: PropTypes.string,
      fieldText: PropTypes.string,
      fieldImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default DossierElement;
