import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import TeaserGeneral, {
  teaserGeneralPropTypes,
} from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-project";
import useTeaserQuery from "./use-teaser-query";

const ComponentTeaserListWrap = (props) => {
  const dispatch = useDispatch();
  const { nodes, loading } = useTeaserQuery(props.manualNodes, {
    ...props,
  });

  const pushPagerFullPageConfig = () => {
    if (props.pagerFullPage && nodes) {
      const pagerFullPagesConfig = {
        id: props.id,
        items:
          props.mode === "manual"
            ? props.manualNodes.map((item) => item.entity)
            : nodes.items,
        overviewLink: props.pagerFullPageOverviewLink,
      };

      dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  useEffect(() => {
    pushPagerFullPageConfig();
  }, [nodes]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (nodes.length) {
    return (
      <>
        {nodes.map((item, index) => (
          // Would this not just be 'col-16 col-lg' ?
          <div key={index} className="col-8 col-lg-4">
            {(() => {
              switch (item.entityBundle) {
                case "news":
                  return (
                    <TeaserNews
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                    />
                  );
                case "person":
                  return (
                    <TeaserPerson
                      count={props.limit}
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                    />
                  );
                case "landingpage":
                  return (
                    <TeaserGeneral
                      item={item}
                      pagerFullPage={props.pagerFullPage}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        ))}
      </>
    );
  }
};

ComponentTeaserListWrap.propTypes = {
  limit: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      teaserNewsPropTypes,
      teaserEventPropTypes,
      teaserPersonPropTypes,
      teaserGeneralPropTypes,
      teaserProjectPropTypes,
    ])
  ),
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["auto", "manual"]),
  type: PropTypes.oneOf(["news", "person", "event", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  pagerFullPage: PropTypes.bool,
  pagerFullPageOverviewLink: PropTypes.object,
};

export default ComponentTeaserListWrap;
